import React from 'react'

const PrimaryTwoColsSection = ({subtitle, title, text, image}) => {
  return (
    <section className='px-4 pt-16 border-b-12 lg:border-b-36 border-primary md:border-secondary bg-primary relative shadow-section'>
      <div className='w-full max-w-screen-xl mx-auto'>
        <h2 className='text-secondary text-lg uppercase font-bold has-decorator mb-10'>{subtitle}</h2>
        <div className='flex flex-wrap -mx-4'>
          <div className='w-full lg:w-1/2 px-4 relative lg:static'>
            <div className='pl-6 xl:pl-0 md:pr-20 xl:mt-8 relative z-10'>
              <h3 
                className='font-semibold text-secondary text-lg md:text-3xl' 
                data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'
              >
                {title}
              </h3>
              <div
                className='mt-8 lg:mt-16 mb-16 xl:mb-28 lg:mb-0 xl:pr-12 font-medium md:text-lg text-secondary' 
                data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'
              >
                <p>
                  {text}
                </p>
              </div>
            </div>
          </div>
          <div className='w-full lg:w-1/2 lg:px-4 relative lg:static'>
            <div className='lg:absolute lg:bottom-0 right-0 w-full lg:w-1/2 h-full'>
              {image}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PrimaryTwoColsSection
