import React from 'react'

const SubpageHero = ({heroBg, heroContent, title, text}) => {
  return (
    <section className='border-b-12 lg:border-b-36 border-secondary relative'>
      <div className='w-full relative'>
        <div className='absolute top-0 left-0 w-full h-full'>
          {heroBg}
        </div>

        <div className='px-4 w-full max-w-screen-xl mx-auto py-16 lg:py-36 relative z-10'>
          <div className='pr-32 lg:pr-0'>
            {heroContent}  
          </div>
        </div>
      </div>
      <div className='w-full py-12 bg-primary'>
        <div className='px-4 w-full max-w-screen-xl mx-auto'>
          <div className='xl:max-w-3xl'>
            <h1 
              className='font-semibold text-secondary text-base lg:text-3xl lg:mb-12' 
              data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'
            >
              {title}
            </h1>
            <p 
              className='font-light text-white text-sm lg:text-lg'
              data-sal='slide-up' data-sal-delay='300' data-sal-duration='600' data-sal-easing='ease-in-out'
            >
              {text}
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SubpageHero
