import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Map from '../../../images/svg/pd-map.svg'

const SubpagePDGroup = () => {
  const { t } = useTranslation()
  return (
    <section className='px-4 border-b-12 lg:border-b-36 border-secondary py-12 md:py-24 overflow-hidden relative'>
      <div className='absolute h-full w-full top-0 left-0 overflow-hidden map-box' data-sal='fade' data-sal-delay='100' data-sal-duration='300' data-sal-easing='ease-in-out'>
        <Map className='w-auto xl:w-full h-full absolute left-1/2 xl:left-0 xl:right-0 xl:mr-0 transform -translate-x-1/2 xl:translate-x-0' />
      </div>

      <div className='w-full max-w-screen-xl mx-auto'>
        <h2 className='text-primary text-sm md:text-lg uppercase font-bold has-decorator mb-10'>
          {t('scaleSection.title')}
        </h2>
        <div className='flex flex-wrap -mx-4 relative z-10 xl:mt-32'>
          <div className='w-full md:w-3/4 lg:w-1/2 px-4'>
            <h3
              className='font-semibold md:font-medium text-primary text-sm md:text-xl lg:text-3xl pl-6 md:pl-0 -mt-6 md:mt-0 w-8/12 md:w-auto'
              data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'
            >
              {t('scaleSection.header')}
            </h3>
            <div
              className='hidden xl:block mt-8 lg:mt-12 mb-16 lg:mb-0 font-light md:text-lg'
              data-sal='slide-up' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'
            >
              <p>{t('scaleSection.lead')}</p>
            </div>
          </div>
        </div>
        <div className='flex flex-wrap items-center -mx-2 md:-mx-4 xl:-mx-4 mt-75vw xl:mt-24 xl:pr-56'>
          <div className='w-1/2 md:w-1/4 px-2 md:px-4 xl:px-4'>
            <div className='relative pl-3 md:pl-4 md:pr-2 leading-tight mt-12 sm:mt-0' data-sal='slide-left' data-sal-delay='300' data-sal-duration='400' data-sal-easing='ease-in-out'>
              <div className='bg-primary h-full w-1 absolute left-0'></div>
              <h4 className='text-base text-primary lg:text-2xl font-medium'>{t('scaleSection.numbers.01.header')}</h4>
              <p className='text-sm lg:text-lg 2xl:text-xl font-light m-0'>
                {t('scaleSection.numbers.01.body')}
                <br />
                {t('scaleSection.numbers.01.body2')}
              </p>
            </div>
          </div>
          <div className='w-1/2 md:w-1/4 px-2 md:px-4 xl:px-4'>
            <div className='relative pl-3 md:pl-4 pr-8 md:pr-2 leading-tight mt-12 sm:mt-0' data-sal='slide-left' data-sal-delay='500' data-sal-duration='400' data-sal-easing='ease-in-out'>
              <div className='bg-primary h-full w-1 absolute left-0'></div>
              <h4 className='text-base text-primary lg:text-2xl font-medium'>{t('scaleSection.numbers.02.header')}</h4>
              <p className='text-sm lg:text-lg 2xl:text-xl font-light m-0'>
                {t('scaleSection.numbers.02.body')}
                <br />
                {t('scaleSection.numbers.02.body2')}
              </p>
            </div>
          </div>
          <div className='w-1/2 md:w-1/4 px-2 md:px-4 xl:px-4'>
            <div className='relative pl-3 md:pl-4 md:pr-2 leading-tight mt-12 sm:mt-0' data-sal='slide-left' data-sal-delay='500' data-sal-duration='400' data-sal-easing='ease-in-out'>
              <div className='bg-primary h-full w-1 absolute left-0'></div>
              <h4 className='text-base text-primary lg:text-2xl font-medium'>{t('scaleSection.numbers.03.header')}</h4>
              <p className='text-sm lg:text-lg 2xl:text-xl font-light m-0'>
                {t('scaleSection.numbers.03.body')}
                <br />
                {t('scaleSection.numbers.03.body2')}
              </p>
            </div>
          </div>
          <div className='w-1/2 md:w-1/4 px-2 md:px-4 xl:px-4'>
            <div className='relative pl-3 md:pl-4 md:pr-2 leading-tight mt-12 sm:mt-0' data-sal='slide-left' data-sal-delay='500' data-sal-duration='400' data-sal-easing='ease-in-out'>
              <div className='bg-primary h-full w-1 absolute left-0'></div>
              <h4 className='text-base text-primary lg:text-2xl font-medium'>{t('scaleSection.numbers.04.header')}</h4>
              <p className='text-sm lg:text-lg 2xl:text-xl font-light m-0'>
                {t('scaleSection.numbers.04.body')}
                <br />
                {t('scaleSection.numbers.04.body2')}
              </p>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

export default SubpagePDGroup
