import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Seo from '../components/seo'
import ContactSection from '../components/organisms/ContactSection'
import SubpageHero from '../components/organisms/SubpageHero'
import SubpagePDGroup from '../components/organisms/SubpagePDGroup'
import PrimaryTwoColsSection from '../components/organisms/PrimaryTwoColsSection'
import Banner from '../components/organisms/Banner'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {graphql} from 'gatsby';

const PdRefractoriesPage = (props) => {
  const { t } = useTranslation()
  return (
    <Layout>
      <Seo title={t('pageTitle')}/>
      <SubpageHero
        heroBg={
          <StaticImage
            src="../images/pd-refractories/pd-refractories-air3.jpg"
            layout='fullWidth'
            transformOptions='fit'
            quality={95}
            placeholder=''
            alt={t('pageTitle')}
            style={{
              height: `100%`,
            }}
          />
        }
        heroContent={
          <StaticImage
            src="../images/products-pd-refractories.png"
            quality={95}
            width={273}
            placeholder=''
            alt={t('pdGroupAlt')}
          />
        }
        title={t('pageTitle')}
        text={t('pageLead')}
      />
      <SubpagePDGroup />
      <PrimaryTwoColsSection
        subtitle={t('traditionSection.title')}
        title={t('traditionSection.header')}
        text={t('traditionSection.lead')}
        image={
          <StaticImage
            src="../images/pd-refractories/skala3.jpg"
            layout='fullWidth'
            transformOptions='fit'
            alt={t('traditionSection.title')}
            style={{
              height: `100%`,
            }}
          />
        }
      />
      <Banner
        image={
          <StaticImage
            src="../images/banner1.jpg"
            layout='fullWidth'
            transformOptions='fit'
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt={t('bannerSection.header')}
            style={{
              height: '100%',
            }}
        />
        }
        text={t('bannerSection.header')}
    />
      <ContactSection />
  </Layout>
  )
}

export default PdRefractoriesPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "pdRefractoriesPage"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
